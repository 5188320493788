import React, { useState } from 'react';
// IMPORT TAB AND DESKTOP BUTTON TO FORM COMPONENT
import Tab from '../common/Tab';
import DesktopButton from '../DesktopButton';
// IMPORT PORTFOLIO "DESKTOP" ICON
import RecycleBin from '../../assets/recycleBin-logo.svg';
// IMPORT PROJECT IMAGES
import binOne from '../../assets/bin-one.webp';
import binTwo from '../../assets/bin-two.webp';
import binThree from '../../assets/Katrina-Bin-Image.webp';
// IMPORT BIN COMPONENTS
import BinComponents from '../BinComponents';

const Bin = () => {
  // OPEN PORTFOLIO TABS
  const [isOpenBin, setIsOpenBin] = useState(false);

  return (
  <div className='binTab'>
     <DesktopButton isOpen={isOpenBin} setIsOpen={setIsOpenBin} logo={RecycleBin} name={'Recycle Bin'}/>
      {isOpenBin && (<Tab isOpen={isOpenBin} setIsOpen={setIsOpenBin} minHeight={'500px'} minWidth={'400px'} dX={1020} dY={-200} dWidth={400} dHeight={320} image={RecycleBin} name={'Recycle Bin'} title={''} subtext={`3 Deleted Items`} content={<div><div className='row'><div className='col-5'><BinComponents link='https://github.com/Thatskat/Week-1-HTML-Basic' image={binOne} text={'My first HTML project'}/><div className='col'><BinComponents link='https://github.com/Thatskat/week2css' image={binTwo} text={'CSS time'}/></div></div></div><BinComponents link='#' image={binThree} text={'Kat hard at work'} /></div>}/>)}
  </div> 
  )
}

export default Bin