import React, { useState } from 'react';
// IMPORT TAB AND DESKTOP BUTTON TO FORM COMPONENT
import Tab from '../common/Tab';
import DesktopButton from '../DesktopButton';
// IMPORT PORTFOLIO "DESKTOP" ICON
import myDocuments from '../../assets/myDocuments-logo.svg';
// PROJECTS IMAGES
import Grimleys from '../../assets/Grimleys-website-screenshot-min.webp';
import Coorabin from '../../assets/coorabin.webp';
// IMPORT REACT ICONS
import { AiTwotoneTags } from 'react-icons/ai';

const Documents = () => {

  // OPEN PORTFOLIO TABS
const [isOpenDocuments, setIsOpenDocuments] = useState(false);

  return (
    <div>
      <DesktopButton isOpen={isOpenDocuments} setIsOpen={setIsOpenDocuments} logo={myDocuments} name={'My Documents'}/>
      {isOpenDocuments && (<Tab isOpen={isOpenDocuments} setIsOpen={setIsOpenDocuments} minHeight={'400px'} minWidth={'800px'} dX={500} dY={-75} dWidth={500} dHeight={400} image={myDocuments} name={'My Documents'} title={'Projects'} subtext={`Here are examples of some of my past work and stuff I'm currently working on:`} content={ <div className='row'><div className='col projectsSection'><a href='https://grimleys.com.au/' target='_blank' rel='noreferrer'><img src={Grimleys} alt='Grimleys Webiste Screenshot' /><p className='tags'>Grimleys</p><div className='middle'><p className='text'><strong><AiTwotoneTags />Wordpress, UI/UX Design, CSS, HTML</strong></p></div></a></div><div className='col projectsSection'><a href='https://coorabinjrt.com/' target='_blank' rel='noreferrer'><img src={Coorabin} alt='Coorabin JRT Webiste Screenshot' /><p className='tags'>Coorabin JRT</p><div className='middle'><p className='text'><strong><AiTwotoneTags />Wordpress, UI/UX Design, CSS, HTML</strong></p></div></a></div></div> } />)}
    </div>
  )
}

export default Documents