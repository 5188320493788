import React, { useState } from 'react';
// IMPORT TAB AND DESKTOP BUTTON TO FORM COMPONENT
import Tab from '../common/Tab';
import DesktopButton from '../DesktopButton';
// IMPORT PORTFOLIO "DESKTOP" ICON
import Logo from '../../assets/email-logo.svg';
// SOCIAL MEDIA ICONS
import Github from '../../assets/github.svg';
import LinkedIn from '../../assets/linkedin.svg';

const Email = () => {
  // OPEN PORTFOLIO TABS
  const [isOpenEmail, setIsOpenEmail] = useState(false);

  return (
    <div>
      <DesktopButton isOpen={isOpenEmail} setIsOpen={setIsOpenEmail} logo={Logo} name={'E-Mail'}/>
      {isOpenEmail && (<Tab isOpen={isOpenEmail} setIsOpen={setIsOpenEmail} minHeight={'320px'} minWidth={'400px'} dX={1050} dY={-200} dWidth={400} dHeight={320} image={Logo} name={'E-Mail'} title={'Where to find me?'} subtext={`Want to see more of my work? Find me on the following platforms:`} content={<div className='socialMediaEmail row'><a href='https://www.linkedin.com/in/katrina-gill-a66090259/'target='_blank' rel='noreferrer' className='col'><div className='layoutEmail'><img src={LinkedIn} alt='LinkedIn Icon'/><p>LinkedIn</p></div></a><a href="https://github.com/Thatskat" target='_blank' rel='noreferrer' className='col'><div className='layoutEmail'><img src={Github} alt='GitHub Icon'/><p>Github</p></div></a></div>} />)}
    </div>
  )
}

export default Email