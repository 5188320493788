import React from 'react';

const StartLinks = ({name, subName, icon}) => {
  return (
    <div className='row linkHighlight'>
      <div className='col-1'>
        <img src={icon} alt="" />
      </div>
      <div className='col-10 ms-1'>
        <p className='applicationName'>{name}</p>
       <p className='applicationSubName'>{subName}</p>
      </div>
    </div> 
  )
}

export default StartLinks