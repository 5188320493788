import React, { useState } from 'react';
// COMPONENTS
import StartLinks from '../StartLinks';
// ASSETS
// NAV ICONS
import StartLogo from '../../assets/start-logo.png';
import WebDevKatLogo from '../../assets/logo/webdevkatlogo.svg';
import InternetLogo from '../../assets/internet-logo.svg';
import EmailLogo from '../../assets/email-logo.svg';
import MinesweeperLogo from '../../assets/minesweeper-logo.svg';
import SolitaireLogo from '../../assets/solitaire-logo.svg';
import PlayerLogo from '../../assets/windows-player-logo.svg';
import CommandLineLogo from '../../assets/commandline-logo.svg';
import myComputerLogo from '../../assets/myComputer-logo.svg';
import myDocumentsLogo from '../../assets/myDocuments-logo.svg';
import recycleLogo from '../../assets/recycleBin-logo.svg';
// SOCIAL MEDIA ICONS
import Github from '../../assets/github.svg';
import LinkedIn from '../../assets/linkedin.svg';
// REACT ICONS
import { AiOutlineSound } from 'react-icons/ai';
import { CgEthernet } from 'react-icons/cg';

const Footer = () => {

  let time = new Date();
  let hour = time.getHours();
  let minutes = time.getMinutes();
  let amPM = hour > 11 ? " PM" : " AM";
  hour = hour % 12;
  hour = hour ? hour : 12;
  minutes = minutes < 10 ? '0'+minutes : minutes;
  
  const [currentTime, setTime] = useState(`${hour}:${minutes}`);

  // START MODAL STATES
  const [isOpen, setIsOpen] = useState(false);

  const checkTime = () => {
    setTime(`${hour}:${minutes}`);
  }

  setInterval(checkTime, 1000);

  return (
    <footer>
      <div className='row'>
        <div className='col-2'>
          <button className='btn btn-success' onClick={()=>{isOpen === false ? setIsOpen(true) : setIsOpen(false)}}><img src={StartLogo} alt="WebDevKat Start Button Logo" /><span>start</span></button>
        </div>
        {isOpen && (
       <div className='windowStart'>
        <div className='userTop'><img src={WebDevKatLogo} alt="WebDevKat Logo" /><span>WebDevKat</span></div>
        <div className='row mainSection'>
          <div className='col lightBackground'>
            <div>
          <StartLinks name="Internet" subName="Internet Explorer" icon={InternetLogo}/>
          <StartLinks name="E-Mail" subName="Outlook Express" icon={EmailLogo}/>
          <div className='divider'></div>
            <button className='startAppButtons linkHighlight'><img src={MinesweeperLogo} alt="Minesweeper logo" style={{marginTop: '0.2rem'}}/><span>Minesweeper</span></button>
            <button className='startAppButtons linkHighlight'><img src={SolitaireLogo} alt="Solitaire Logo" style={{marginTop: '0.2rem', width: '3rem'}} /><span>Solitaire</span></button>
            <button className='startAppButtons linkHighlight'><img src={PlayerLogo} alt="Windows Media Player Icon" style={{marginTop: '0.5rem', width: '4rem', marginLeft:'-1.7rem'}}/><span>Windows Media <span style={{marginLeft: '2.1rem', paddingTop: "-4rem" }} >Player</span></span></button>
            <button className='startAppButtons linkHighlight'><img src={CommandLineLogo} alt="Command Prompt Icons" className='mt-1'/>Command Prompt</button>
            </div>
          </div>
          <div className='col darkBackground'>
            <div className='startUpTop'>
          <StartLinks name=" " subName="My Computer" icon={myComputerLogo}/>
          <StartLinks name=" " subName="My Documents" icon={myDocumentsLogo}/>
          <StartLinks name=" " subName="Recycle Bin" icon={recycleLogo} className='recycleBin'/>
          </ div>
          <div className='divider'></div>
          <h3>Social Profiles</h3>
          <div className='socialProfiles'>
          <a href="https://www.linkedin.com/in/katrina-gill-a66090259/" target='_blank' rel='noreferrer'><StartLinks name=" " subName="LinkedIn" icon={LinkedIn}/></a>
          <a href="https://github.com/Thatskat" target='_blank' rel='noreferrer'><StartLinks name=" " subName="GitHub" icon={Github}/></a>
          </ div>
          </div>
        </div>
        <div className='startBottomSection'>

          </div>
        </div>
      )}
        <div className='col-7'>
        </div>
        <div className='col-2 time'>
          <AiOutlineSound className='footerIcons'/>
          <CgEthernet className='footerIcons ethernet' />
          <div className='timeCount'>
          {currentTime}{amPM} 
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer