import React from 'react';
import Cross from '../../assets/windows-xp-cross.svg';
import Maximise from '../../assets/maximise-logo.svg';
import Minimise from '../../assets/minimise-icon.svg';
import { Rnd } from "react-rnd";

const Tab = ({ isOpen, setIsOpen, minHeight, minWidth, dX, dY, dWidth, dHeight, image, name, title, subtext, content }) => {
  return (
    <Rnd  minHeight={minHeight}  minWidth={minWidth}
    default={{
    x: dX,
    y: dY,
    width: dWidth,
    height: dHeight
  }} className='webpageBorder'><div className='userTop'><img src={image} alt="Internet Logo" />{name}
  <button className='btn btn-danger me-2' onClick={ () => isOpen === true ? setIsOpen(false) : setIsOpen(true) }><img src={Cross} alt="Windows Exit Cross" /></button>
  <button className='btn btn-primary' onClick={() => {}}><img src={Maximise} alt="Windows Maximise Tab Page" /></button>
  <button className='btn btn-primary' onClick={() => isOpen === true ? setIsOpen(false) : setIsOpen(true)}><img src={Minimise} alt="Windows Minimise Tab Page" id='minimiseLogo' /></button>
</div>
  <div className='tabContext'>
  <h1>{title}</h1>
  <p className='subText'>{subtext}</p>
  {content}
  </div></Rnd>
  )
}

export default Tab