import React  from "react";
// IMPORT REACT ROUTER DOM
import { Routes, Route, BrowserRouter} from 'react-router-dom';
// IMPORT PAGES
import Main from "./pages/Main";
import PageNotFound from "./pages/PageNotFound";



function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />}/>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  )

}

export default App;
