import React, { useState } from 'react';
// IMPORT TAB AND DESKTOP BUTTON TO FORM COMPONENT
import Tab from '../common/Tab';
import DesktopButton from '../DesktopButton';
// IMPORT PORTFOLIO "DESKTOP" ICONS
import MyComputer from '../../assets/myComputer-logo.svg';
import htmlFile from '../../assets/htmlFile.svg';
import HtmlImage from '../../assets/html-image-min.png';
import WebDevKat from '../../assets/logo/webdevkatlogo.svg';


const Computer = () => {
// OPEN PORTFOLIO TABS
const [isOpenComputer, setIsOpenComputer] = useState(false);
const [isOpenHTML, setIsOpenHTML] = useState(true);

  return (
    <div>
   <DesktopButton isOpen={isOpenComputer} setIsOpen={setIsOpenComputer} logo={MyComputer} name={'My Computer'}/>
      {isOpenComputer && (<Tab isOpen={isOpenComputer} setIsOpen={setIsOpenComputer} minHeight={'200px'} minWidth={'300px'} dX={400} dY={-50} dWidth={300} dHeight={200} image={MyComputer} name={'My Computer'} title={''} subtext={'You Currently Have 1 File'}  content={<div className='htmlDoc'><DesktopButton isOpen={isOpenHTML} setIsOpen={setIsOpenHTML} logo={htmlFile} name={'webdevkat.html'}/></div>}/>)}
      {isOpenHTML && (<Tab isOpen={isOpenHTML} setIsOpen={setIsOpenHTML} minHeight={'450px'} minWidth={'800px'} dX={130} dY={-20} dWidth={300} dHeight={450} image={WebDevKat} name={'Welcome to WebDevKat'} title={"Hello There, I'm Kat"} subtext={'Web Developer'}  content={<div className='row'> <div className='col-7'>
        <p>I love developing mobile and web applications that are both visually compelling and accessible.</p>
        <p>I’m currently studying a Diploma of Information Technology majoring in Front and Back End Web Development. I have previously attained a Certificate IV in Information Technology majoring in Web Development in 2022.</p>
      </div>
      <div className='col-5'>
        <img src={HtmlImage} alt="webdevkat" style={{marginTop: '-5rem', marginLeft: '3rem'}} />
        <div>
      </div>
      </div>
      </div> } />)}
   </div>
  )
}

export default Computer