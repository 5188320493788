import React, { useState } from 'react';
// IMPORT TAB AND DESKTOP BUTTON TO FORM COMPONENT
import Tab from '../common/Tab';
import DesktopButton from '../DesktopButton';
// IMPORT PORTFOLIO "DESKTOP" ICONS
import InternetLogo from '../../assets/internet-logo.svg';

const Internet = () => {

// OPEN PORTFOLIO TABS
const [isOpenInternet, setIsOpenInternet] = useState(false);

  return (
    <div>
<DesktopButton isOpen={isOpenInternet} setIsOpen={setIsOpenInternet} logo={InternetLogo} name={'Internet Explorer'} />
{isOpenInternet && (<Tab isOpen={isOpenInternet} setIsOpen=   {setIsOpenInternet} minHeight={'383px'} minWidth={'500px'} dX={200} dY={50} dWidth={600} dHeight={383} image={InternetLogo} name={'Internet Explorer'} title={'My Skills'} subtext={`Funnily enough I can code and design things, Heres a brief overview of the languages and programs that Ive learnt:`} content={<ul>
  <div className='row'>
    <div className='col-4'>
    <li>HTML & CSS</li>
    <li>React.js</li>
    <li>UX/UI Design</li>
    <li>Figma</li>
    </div>
    <div className='col-4'>
    <li>JavaScript</li>
    <li>Node.js</li>
    <li>WordPress</li>
    <li>Git</li>
    </div>
    <div className='col-4'>
    <li>PHP</li>     
    <li>SQL</li>
    <li>Adobe Suite</li>
    </div>
  </div>
</ul>} />)}
</div>
  )
}

export default Internet