import React from 'react'

const BinComponents = ({image, link, text}) => {
  return (
    <div className='binComponent'>
      <a target='_blank' href={link} rel='noReferrer'><img src={image} alt='First HTML project'/>
      <p>{text}</p></a>
    </div>
  )
}

export default BinComponents