import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className='pageNotFound'>
     <div>
      <h1 className='typewriter m-3'>Error 404: Page Not Found</h1>
      <p className='m-3'>Return <Link to='/'>Home</Link></p>
     </div>
    </div>
  )
}

export default PageNotFound