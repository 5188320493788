import React  from 'react';

// IMPORT FOOTER COMPONENT
import Footer from '../components/common/Footer';

// IMPORT DESKTOP SECTIONS
import Internet from '../components/sections/Internet';
import Computer from '../components/sections/Computer';
import Documents from '../components/sections/Documents';
import Email from '../components/sections/Email';
import Bin from '../components/sections/Bin';

const Main = () => {

  return (
    <div>

      <Internet />
     
      <Computer />

      <Documents />

      <Email />

      <Bin />

      <Footer />
    </div>
  )
}


export default Main